import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import courseService from "../../services/CourseService";
import ICourse from "../../interfaces/ICourse";
import { ImagePicker } from "../../components/ImagePicker";
import "./index.scss";
interface ModalCoursesProps {
  show: boolean;
  handleClose: () => void;
  course: ICourse | null;
}

const ModalCourses: React.FC<ModalCoursesProps> = ({
  show,
  handleClose,
  course,
}) => {
  const [error, setError] = useState<boolean>(false);
  const [playlist_youtube, setPlaylist_youtube] = useState<string>("");
  const [image, setImage] = useState<File | null>(null); // Armazena o arquivo da imagem
  const [imageUrl, setImageUrl] = useState<string | null>(""); // Armazena a URL da imagem
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (course) {
      setName(course.name);
      setDescription(course.description);
      setPlaylist_youtube(course.playlist_youtube || "");
      setImageUrl(course.image || ""); // Carrega a URL da imagem existente
      setImage(null); // Limpa o arquivo de imagem ao carregar o curso
    } else {
      setName("");
      setDescription("");
      setPlaylist_youtube("");
      setImageUrl(null);
      setImage(null);
    }
  }, [course]);

  const handleCreate = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = {
      name,
      description,
      playlist_youtube,
      image: "",
    };

    courseService
      .create(data, image!)
      .then(() => {
        setImage(null);
        handleClose();
      })
      .catch(() => {
        setImage(null);
        setError(true);
      });
  };

  const handleSave = async () => {
    if (!course) return;

    const updatedCourseData = {
      name,
      description,
      playlist_youtube,
      image: "",
    };
    if (image) {
      courseService
        .update(course.id, updatedCourseData, image)
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      courseService
        .update(course.id, updatedCourseData)
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          setError(true);
        });
    }
  };

  const handleFileChange = async (file: File | null) => {
    setImage(file);
    if (file) {
      console.log(file);
      // const response = await usersService.uploadPhotoProfile(file);
      if (file) {
        console.log("Imagem carregada com sucesso!");
      } else {
        console.error("Erro ao carregar a imagem:", file);
      }
    }
  };

  const handleCloseModal = () => {
    setName("");
    setDescription("");
    setPlaylist_youtube("");
    setImage(null);
    setImageUrl(null);
    setError(false); // Limpa qualquer erro
    handleClose(); // Chama a função de fechamento do modal
  };

  return (
    <Modal show={show} onHide={handleCloseModal} size="xl">
  <Modal.Header closeButton>
    <Modal.Title>
      {course ? "Atualizar Curso" : "Cadastrar Curso"}
    </Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <Form>
      <Form.Group className="mb-1">
        <Form.Label>
          <strong>Nome do curso</strong>
        </Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label>
          <strong>Descrição do Curso</strong>
        </Form.Label>
        <Form.Control
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label>
          <strong>Link Play_list</strong>
        </Form.Label>
        <Form.Control
          value={playlist_youtube}
          onChange={(e) => setPlaylist_youtube(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-1">
        <Form.Label>
          <strong>Banner do Curso</strong>
        </Form.Label>
        <div className="d-flex justify-content-center mt-4 mb-3 custom-image">
          <ImagePicker
            handleFileChange={handleFileChange}
            initialImage={imageUrl || "https://i.postimg.cc/9FrSyggq/Favico.jpg"}
          />
        </div>
      </Form.Group>
    </Form>
    <br />
    {error && (
      <Alert variant="danger" onClose={() => setError(false)} dismissible>
        Erro ao cadastrar curso.
      </Alert>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Cancelar
    </Button>
    <Button type="submit" onClick={course ? handleSave : handleCreate}>
      {course ? "Atualizar" : "Salvar"}
    </Button>
  </Modal.Footer>
</Modal>

  );
};

export default ModalCourses;
