
import { CoursesCreateDto } from "../interfaces/ICoursesCreateDto";
import { CoursesUpdateDto } from "../interfaces/ICoursesUpdateDto";
import { MulterS3File } from "../interfaces/IMulterS3File";
import api from "./api";


class CourseService {
  async getAll(): Promise<any> {
    return api
      .get("/courses/all-admin")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async create(data: any, file: File): Promise<any> {
    const formData = new FormData();
  
    // Adiciona os dados ao FormData
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  
    // Adiciona o arquivo ao FormData
    formData.append("file", file); // "file" é o nome do campo que o backend espera
  
    return api({
      url: "/courses/create",
      method: "POST",
      timeout: 5000,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data", // Para indicar que os dados estão em multipart
      },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  

  async delete(idCourse: number): Promise<any> {
    return api
      .delete(`courses/${idCourse}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


  async update(idCourse: number, data: any, file?:File): Promise<any> {   
    const formData = new FormData();
  
    // Adiciona os dados ao FormData
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if(file){
      // Adiciona o arquivo ao FormData
    formData.append("file", file); // "file" é o nome do campo que o backend espera
  
    }
   
    return api({
      url: `/courses/update/${idCourse}`,
      method: "PUT",
      timeout: 5000,
      data: formData,
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
}

async uploadPhotoBanner(file: File): Promise<any> {
 
    const formData = new FormData();
    formData.append("file", file);

    return api({
      url: `/courses/upload-banner/`,
      method: "PUT",
      timeout: 5000,
      data: formData,
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  
}




  
}

const courseService = new CourseService();
export default courseService;
