
import { useRef, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
// import AuthContext from '../../context/auth';
import { MdFileUpload } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

export const ImagePicker: React.FC<{
    handleFileChange: (file: File | null) => void;
    initialImage?: string | null;
    disabled?: boolean;
}> = ({ handleFileChange, initialImage, disabled }) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | undefined | null>(initialImage);
    // const { storePhotoProfile } = useContext(AuthContext);
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false); // Controla o botão "Enviar"

  

    useEffect(() => {
        if (!selectedFile) {
            setPreview(initialImage ?? undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        setIsSendDisabled(true); // Desabilita o botão "Enviar" após o carregamento da imagem
      
        return () => URL.revokeObjectURL(objectUrl);
    }, [initialImage, selectedFile]);

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(null);
            return;
        }
        const file = e.target.files[0];
        setSelectedFile(file);
        handleFileChange(file);       
    };

    
    const handleClick = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    const handleSend = () => {
        console.log('imagem selecionada', selectedFile)
        setIsSendDisabled(true)
    }

    return (
        <>
            {!disabled && (
                <input
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    ref={inputFile}
                    onChange={onSelectFile}
                />
            )}
            <div
                style={{
                    maxHeight: "350px",
                    maxWidth: "200px",
                    minHeight: "250px",
                    minWidth: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: disabled ? "default" : "pointer",
                    flexDirection: "column"
                }}
                onClick={handleClick}
            >
                <div className="p-2" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image
                            // className="rounded-circle"
                            src={preview!}
                            fluid
                            style={{
                                maxWidth: "200%",
                                maxHeight: "300%",
                                objectFit: "cover",
                                borderRadius: "0", // Remove bordas arredondadas
                                margin: "8px", // Adiciona uma margem sutil ao redor da imagem
                                backgroundColor: "#f0f0f0", // Cor de fundo bem clarinha
                                padding: "4px", // Adiciona um padding interno para separar a imagem do fundo
                            }}
                        />
                    
                </div>

                {!disabled && (
                    <div className="p-2" style={{ width: "100%", display: "flex", justifyContent: "center", gap: "10px"}}>
                        <button type="button" className="btn btn-warning" disabled={isSendDisabled} onClick={handleSend}>
                            <MdFileUpload /> Enviar
                        </button>                        
                    </div>
                )}
            </div>
        </>
    );
}
